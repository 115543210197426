const querystring = require('querystring');

require('@yandex-int/rum-counter/dist/bundle/implementation');
require('@yandex-int/rum-counter/dist/bundle/onload');
require('@yandex-int/rum-counter/dist/bundle/send');

//@todo для режима дебага и тестирования https://github.yandex-team.ru/RUM/rum-counter#Разметка-блокировщиков-рекламы
const rumdebug = querystring.parse(location.search.slice(1)).rumdebug;

if (rumdebug) {
    require('@yandex-int/rum-counter/debug/blockstat');
}
