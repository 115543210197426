(function () {
    if (typeof window === 'undefined') {
        return;
    }

    if (Element && typeof Element.prototype.matches !== 'function') {
        Element.prototype.matches = Element.prototype.msMatchesSelector
            || Element.prototype.mozMatchesSelector
            || Element.prototype.webkitMatchesSelector
            || function matches(selector) {
                const element = this;
                const elements = (element.document || element.ownerDocument).querySelectorAll(selector);
                let index = 0;

                while (elements[index] && elements[index] !== element) {
                    ++index;
                }

                return Boolean(elements[index]);
            };
    }

    if (Element && typeof Element.prototype.closest !== 'function') {
        Element.prototype.closest = function closest(selector) {
            let element = this;

            while (element && element.nodeType === 1) {
                if (element.matches(selector)) {
                    return element;
                }

                element = element.parentNode;
            }

            return null;
        };
    }

})();
